import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

import "./App.css";

import Cookies from "js-cookie";

const ID_TOKEN_COOKIE = "id_token";
const COOKIE_EXPIRE_DAYS = 30;
const MEETING_MAINPAGE_URL = "https://app.aimeet.cc";   // https://app.aimeet.cc | http://localhost:3004

function getSecondLevelDomain() {
  const hostname = window.location.hostname;
  const parts = hostname.split(".");

  // 如果域名部分有三个以上的部分（如 xxx.zz.com）则提取二级域名, 否则返回完整域名
  if (parts.length > 2) {
      return parts.slice(-2).join(".");
  } else {
      return hostname;
  }
}

const App = () => {

  const { user, isLoading, loginWithPopup, getIdTokenClaims } = useAuth0();
  const [ initialAuthenticated, setInitialAuthenticated ] = useState(true);
  const [ aimeetLogoImgLoaded, setAimeetLogoImgLoaded ] = useState(false);
  const [ mainFuncIntroImgLoaded, setMainFuncIntroImgLoaded ] = useState(false);
  const idTokenCookie = Cookies.get(ID_TOKEN_COOKIE);

  const funcs = [
    { image: "translate.png",    title: "Translation",       content: "Support realtime translation, breaking through language communication barriers" },
    { image: "transcript.png",   title: "Transcript",        content: "Convert audio to text in real time for easy review of meeting content" },
    { image: "unlimit_time.png", title: "Unlimited",         content: "You can create a meeting anytime and no time limit" },
    { image: "share_screen.png", title: "Share screen",      content: "Support screen sharing, you can express your ideas more effectively" },
    { image: "logo192.png",      title: "Excellent quality", content: "High quality audio and video, bring for better conversations"}
  ];

  useEffect(() => {
    if (!isLoading) {
      const authed = user !== undefined;
      setInitialAuthenticated(authed);

      if (user) {
        if (authed && !initialAuthenticated) {
          window.location.replace(MEETING_MAINPAGE_URL);
        }
      }
    }
  }, [user, isLoading]);

  return (
    <div className="app_container">
      <div className="top_bar">
        <div className="div_top_bar_logo">
          <img
            src="aimeet.png"
            onLoad={() => {
              setAimeetLogoImgLoaded(true);
            }}
          >
          </img>
        </div>
        {
          aimeetLogoImgLoaded && (
            idTokenCookie ? (
              <div
                className="div_my_meeting"
                onClick={() => {
                  window.location.replace(MEETING_MAINPAGE_URL);
                }}
              >
                Meeting
              </div>
            ) : (
              <div
                className="div_get_started"
                onClick={async () => {
                  await loginWithPopup();
                  const idToken = await getIdTokenClaims();
                  if (idToken) {
                    Cookies.set(ID_TOKEN_COOKIE, JSON.stringify(idToken), {
                      domain: getSecondLevelDomain(),
                      expires: COOKIE_EXPIRE_DAYS,
                    });
                  }
                }}
              >
                Get Started
              </div>
            )
          )
        }
      </div>
      <div className="div_main_introduce">
        {
          mainFuncIntroImgLoaded && (
            <div className="div_main_introduce_body">
              <div className="div_main_introduce_title">
                Innovative video conferencing platform
              </div>
              <div className="div_main_introduce_content">
                You can always connect with others and communicate effectively
              </div>
              <div
                className="div_free_trial"
                onClick={async () => {
                  if (idTokenCookie) {
                    window.location.replace(MEETING_MAINPAGE_URL);
                  } else {
                    await loginWithPopup();
                    const idToken = await getIdTokenClaims();
                    if (idToken) {
                      Cookies.set(ID_TOKEN_COOKIE, JSON.stringify(idToken), {
                        domain: getSecondLevelDomain(),
                        expires: COOKIE_EXPIRE_DAYS,
                      });
                    }
                  }
                }}
              >
                Free Trial
              </div>
            </div>
          )
        }
        <div className="div_main_func_introduce_img">
          <img
            src='app.jpg'
            onLoad={() => {
              setMainFuncIntroImgLoaded(true);
            }}
          >
          </img>
        </div>
      </div>
      {
        mainFuncIntroImgLoaded && (
          <div className="div_func_introduce">
            <div className="div_why_aimeet_text">
              Why Aimeet?
            </div>
            <div className="div_func_detail">
              {
                funcs.map((func, idx) => (
                  <div className="div_func_detail_item" key={idx}>
                    <div className="div_func_detail_item_img">
                      <img src={func.image}></img>
                    </div>
                    <div className="div_func_detail_item_title">{func.title}</div>
                    <div className="div_func_detail_item_content">{func.content}</div>
                  </div>
                ))
              }
            </div>
          </div>
        )
      }
      {
        mainFuncIntroImgLoaded && (
          <div className="bottom_bar">
            <div className="div_safety_and_compliance">
              <div className="div_safety_and_compliance_title">
                Safety and compliance
              </div>
              <a onClick={() => {
                window.open(`${window.location.href}privacy`, "_blank");
              }}>
                Privacy Policy
              </a>
              <a onClick={() => {
                window.open(`${window.location.href}terms`, "_blank");
              }}
              >
                Terms of Service
              </a>
            </div>
            <div className="div_follow_us">
              <div className="div_follow_us_title">
                  Follow us
              </div>
              <div className="div_follow_us_social_accounts">
                <div
                  className="div_follow_us_social_account_x"
                  onClick={() => {
                    window.open("https://x.com/aimeet525651");
                  }}
                >
                  <img src='twitter.ico'></img>
                </div>
                <div
                  className="div_follow_us_social_account_fb"
                  onClick={() => {
                    window.open("https://www.facebook.com/share/FDpNVSW9Lod6oE1B/");
                  }}
                >
                  <img src='facebook.ico'></img>
                </div>
                <div
                  className="div_follow_us_social_account_discord"
                  onClick={() => {
                    window.open("https://discord.com/channels/1255871139897540689/1255871139897540692");
                  }}
                >
                  <img src='discord.ico'></img>
                </div>
              </div>
            </div>
          </div>
        )
      }


    </div>
  );
};

export default App;
